// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-dashboard-js": () => import("./../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-stake-js": () => import("./../../src/pages/stake.js" /* webpackChunkName: "component---src-pages-stake-js" */)
}

